import React from 'react'
export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 400 400">
    <path
      d="M1739 3725c-171-28-325-75-474-145-504-236-856-683-977-1240-20-92-22-133-23-340 0-208 3-247 23-343 59-273 173-521 337-732 196-251 464-449 760-560 188-70 427-115 615-115 269 0 563 73 808 200 105 55 143 80 127 86-5 3-138 77-295 165s-350 193-430 232l-145 71-130 7c-293 16-505 102-679 275-212 212-309 535-266 881 55 434 316 731 732 833 109 27 323 37 452 21 345-44 597-213 739-496 69-139 107-316 107-505 0-446-223-793-593-921l-79-28 99-49c145-73 404-181 642-268l95-34 34 32c55 53 177 202 230 280 270 401 362 881 257 1348-103 460-382 851-790 1107-145 92-378 183-570 224-130 28-473 36-606 14z"
      transform="matrix(.1 0 0 -.1 0 400)"
    ></path>
    <path
      d="M1835 2905c-228-49-397-198-485-426-69-181-95-418-69-634 53-436 277-694 635-733 399-44 692 170 796 585 20 79 23 113 22 318 0 218-1 235-27 330-49 182-137 333-245 421-73 60-197 120-287 139-85 18-258 18-340 0z"
      transform="matrix(.1 0 0 -.1 0 400)"
    ></path>
  </svg>
)
