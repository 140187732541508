import React from 'react'
import styled from 'styled-components'

const ContainerOuter = styled.span`
  width: 100%;
  display: block;
  padding: 0.7em 0;
  background: #fff;
  @media (max-width: 585px) {
    padding: 2.1em 0;
  }
`
const ContainerInner = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`

const LogoItem = styled.div`
  min-height: 85px;
  width: 100%;
  padding: 0px 20px;
  @media (max-width: 413px) {
    padding: 0px 10px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 100%;
  }
  @media (max-width: 992px) {
    flex: 0 0 25%;
  }
  @media (max-width: 585px) {
    flex: 0 0 44%;
  }
`

const logos = {
  default: [
    {
      src: '/assets/logos/products/alside.svg',
      alt: 'Alside',
    },
    {
      src: '/assets/logos/products/lp-smartside.svg',
      alt: 'LP SmartSide',
    },
    {
      src: '/assets/logos/products/jameshardie.svg',
      alt: 'James Hardie',
      style: {
        minWidth: '150px',
      },
    },
    {
      src: '/assets/logos/products/andersen.svg',
      alt: 'Andersen Windows',
    },
    {
      src: '/assets/logos/products/mezzo.svg',
      alt: 'Mezzo',
    },
    {
      src: '/assets/logos/products/fusion.svg',
      alt: 'Fusion - Quality Vinyl Windows ',
    },
    {
      src: '/assets/logos/products/certainteed.svg',
      alt: 'CertainTeed ',
    },
   
    {
      src: '/assets/logos/products/bbb.svg',
      alt: 'BBB ',
      style: {
        padding: '0 5px',
      },
    },
  ],
  kitchenRemodeling: [
    {
      src: '/assets/logos/products/alside.svg',
      alt: 'Alside',
    },
    {
      src: '/assets/logos/products/lp-smartside.svg',
      alt: 'LP SmartSide',
    },
    {
      src: '/assets/logos/products/jameshardie.svg',
      alt: 'James Hardie',
      style: {
        minWidth: '150px',
      },
    },
    {
      src: '/assets/logos/products/andersen.svg',
      alt: 'Andersen Windows ',
    },
    {
      src: '/assets/logos/products/mezzo.svg',
      alt: 'Mezzo',
    },
    {
      src: '/assets/logos/products/fusion.svg',
      alt: 'Fusion - Quality Vinyl Windows ',
    },
    {
      src: '/assets/logos/products/certainteed.svg',
      alt: 'CertainTeed ',
    },
    {
      src: '/assets/logos/products/bbb.svg',
      alt: 'BBB ',
      style: {
        padding: '0 5px',
      },
    },
  ],
  bathroomRemodeling: [
    {
      src: '/assets/logos/products/mezzo.svg',
      alt: 'Mezzo',
    },
    {
      src: '/assets/logos/products/toto.svg',
      alt: 'Toto',
    },
    {
      src: '/assets/logos/products/bbb.svg',
      alt: 'BBB',
      style: {
        padding: '0 5px',
      },
    },
    {
      src: '/assets/logos/products/blanco.svg',
      alt: 'Blanco',
    },
    {
      src: '/assets/logos/products/fusion.svg',
      alt: 'Fusion - Quality Vinyl Windows ',
    },
    {
      src: '/assets/logos/products/lp-smartside.svg',
      alt: 'LP SmartSide',
    },
    {
      src: '/assets/logos/products/certainteed.svg',
      alt: 'CertainTeed ',
    },
    {
      src: '/assets/logos/products/certainteed.svg',
      alt: 'CertainTeed ',
    },
  ],
  fullDeckPlanSet: [
    {
      src: '/assets/logos/products/duralife.png',
      alt: 'Duralife',
    },
    {
      src: '/assets/logos/products/timbertech.svg',
      alt: 'Timbertech',
    },
    {
      src: '/assets/logos/products/deckorators.png',
      alt: 'Deckorators',
    },
    {
      src: '/assets/logos/products/deckpro.png',
      alt: 'Dekpro',
    },
    {
      src: '/assets/logos/products/fiberondecking.png',
      alt: 'Fiberon Decking',
    },
    {
      src: '/assets/logos/products/barrette-outdoor-living.svg',
      alt: 'Barrette Outdoor Living',
    },
  ]
}

const renderShowcase = footerLogoShowcase => {
  switch (footerLogoShowcase) {
    case 'kitchenremodeling':
      return { items: logos.kitchenRemodeling }
    case 'bathroomremodeling':
      return { items: logos.bathroomRemodeling }
      break
    case 'full-deck-plan-set':
      return { items: logos.fullDeckPlanSet }
      break
    default:
      return { items: logos.default }
  }
}

const ProductLogoShowcase = footerLogoShowcase => {
  const { items, bgColor = '' } = renderShowcase(
    footerLogoShowcase.footerLogoShowcase
  )
  return (
    <ContainerOuter>
      <div className="container shadow-md border-radius">
        <ContainerInner>
          {items.map
            ? items.map(({ src, alt, style }) => (
                <LogoItem>
                  <img
                    src={src}
                    style={{ ...style, userSelect: 'none' }}
                    alt={alt}
                    loading="lazy"
                    draggable={false}
                  />
                </LogoItem>
              ))
            : items}
        </ContainerInner>
      </div>
    </ContainerOuter>
  )
}

export default ProductLogoShowcase
