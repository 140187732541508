export const adRefCity = (def) => {
  //for webpack
  try {
    const adRef = JSON.parse(window.sessionStorage.getItem('rFprAn'))
    if (!adRef || (adRef && !adRef.locphysical)) {
      return def
    }
    let stringBase = 'Serving '
    let cityTable = {}
    fetch('/cities.json')
      .then((r) => console.log('r', r))
      .catch((e) => console.log(e))
    const cityName = cityTable[adRef.locphysical]
    if (!cityName) {
      return def
    }
    return stringBase + cityName + ', CA and surrounding areas'
  } catch (e) {
    return def
  }
}

export const getValueTrackParams = () => {
  try {
    const { city, locphysical, campaigns } = JSON.parse(
      window.sessionStorage.getItem('rFprAn')
    )
    return { city, locphysical, campaigns }
  } catch (e) {
    return {}
  }
}

export const textReplacement = (
  personalizedTextRef,
  replacementContainerClassName,
  textToReplaceWith
) => {
  if (textToReplaceWith) {
    const personalizedElments = document.getElementsByClassName(
      replacementContainerClassName
    )
    for (const personalizedElement of personalizedElments) {
      // remove in from previous text node && replaces with Near You
      // top section removes text after personalized text
      if (textToReplaceWith == ' ') {
        if (
          personalizedElement.nextSibling &&
          personalizedElement.nextSibling.textContent.includes(', KS')
        ) {
          personalizedElement.nextSibling.textContent = ' '
        }

        if (
          personalizedElement.parentNode.innerText.includes('in Kansas City')
        ) {
          personalizedElement.previousSibling.textContent =
            personalizedElement.previousSibling.textContent.replace(
              ' in ',
              personalizedElement.parentNode.nodeName == 'P'
                ? ' near you'
                : ' Near You'
            )
        }
      }
      personalizedElement.innerText = textToReplaceWith
    }
    const title = document.getElementsByTagName('title')[0]
    console.log('ttiel', title.innerText.includes('Kansas City'), { title })
    if (title.innerText.includes('Kansas City')) {
      title.innerText =
        textToReplaceWith == ' '
          ? title.innerText.replace('Kansas City', ' Near You')
          : title.innerText.replace('Kansas City', 'in   ' + textToReplaceWith)
    }
  }
}

export const cityName = (city) => {
  if (city == 'kc') {
    return 'Kansas City'
  }
  if (city == 'wb') {
    return 'Warrensburg'
  }
  if (city == 'c') {
    return 'Columbia'
  }
  if (city == 'jc') {
    return 'Jefferson City'
  }
  if (city == 'sl') {
    return 'St. Louis'
  }
  if (city == 'lo') {
    return 'Lake Ozarks'
  }
  return null
}

// returns a string with value tracked city name for text replacement
export const adRefCityText = async (def, { city, locphysical }) => {
  try {
    // no params passed return default
    if (!(locphysical || city)) {
      return def
    }
    let cityTable = JSON.parse(window.localStorage.getItem('cityTable'))
    if (!cityTable) {
      cityTable = await fetch('/cities.json')
        .then((r) => r.json())
        .then((table) => {
          window.localStorage.setItem('cityTable', JSON.stringify(table))
          return table
        })
    }

    const cityNameFromTable = cityTable[locphysical]
    console.log('cityNameFromTable', cityNameFromTable)
    // check if city exists before returning new string
    // check if city is passed then alter string
    if (!!city && cityNameFromTable === undefined) {
      return cityName(city)
    }
    // if cityName is not found return no city
    if (!cityNameFromTable) {
      return ' '
    }
    // return the cityName when found
    return cityNameFromTable
  } catch (e) {
    // for build step, return the default string
    return def
  }
}

export const getUrlParams = ({ urlParams = [] }) => {
  try {
    const params = JSON.parse(window.sessionStorage.getItem('rFprAn'))
    return urlParams.map((urlParam) => params[urlParam])
  } catch (e) {
    return []
  }
}
