import { useEffect } from 'react'
import { getUrlParams } from '../helpers/adRefTracking'

export const useHiddenmsClass = ({ urlParamsToMatch = [], handler } = {}) => {
  const refs = []
  useEffect(() => {
    const urlParameterValuesPulled = getUrlParams({
      urlParams: urlParamsToMatch.map((urlParam) => urlParam.split('=')[0]), // grab first value because its the key
    })
    let matchesUrlParameters = urlParamsToMatch.map((urlParam) => {
      const urlParamValue = urlParam.split('=')[1]
      const booleans = urlParameterValuesPulled.map(
        (urlParamPulled) => urlParamValue == urlParamPulled
      )
      return booleans.length > 0
        ? booleans.reduce((preValue, curValue) => preValue == curValue)
        : false
    })
    // reduce array again
    matchesUrlParameters =
      matchesUrlParameters.length > 0
        ? matchesUrlParameters.reduce(
            (preValue, curValue) => preValue || curValue
          )
        : false
    if (refs.length > 0 && matchesUrlParameters) {
      refs.map((ref) => {
        if (ref.current) {
          if (!handler) {
            const className = ref.current.parentNode.classList
            ref.current.parentNode.className = className + ' hiddenms'
          } else {
            handler(ref)
          }
        }
        return ref
      })
    }
  })
  return refs
}
